<!-- Top Bar Start -->
<div class="topbar">

    <nav class="navbar-custom">

        <ul class="list-unstyled topbar-right-menu float-right mb-0">

            <li class="dropdown notification-list">
                <a class="nav-link dropdown-toggle nav-user" data-toggle="dropdown" href="#" role="button"
                   aria-haspopup="false" aria-expanded="false">
                    <img src="assets/images/profile.png" alt="user" class="rounded-circle"> <span class="ml-1">{{userName}} <i class="mdi mdi-chevron-down"></i> </span>
                </a>
                <div class="dropdown-menu dropdown-menu-right dropdown-menu-animated profile-dropdown">
                    <!-- item-->
                    <div class="dropdown-item noti-title">
                        <h6 class="text-overflow m-0">Welcome !</h6>
                    </div>
                    <a class="dropdown-item notify-item" (click)="logOut()">
                        <i class="fi-power"></i> <span>Logout</span>
                    </a>

                </div>
            </li>

        </ul>

        <ul class="list-inline menu-left mb-0">
            <li class="float-left">
                <button class="button-menu-mobile open-left disable-btn" (click)="showLeftBar()">
                    <i class="dripicons-menu"></i>
                </button>
            </li>
            <li>
                <div class="page-title-box">
                    <h4 class="page-title"> Sydty</h4>
                </div>
            </li>

        </ul>

    </nav>

</div>

<!-- Top Bar End -->
  <!-- ========== Left Sidebar Start ========== -->
  <div class="left-side-bar" [ngClass]="[ isFavorite ? 'open' : 'close']">
    <div id="remove-scroll">
        <!-- LOGO -->
        <div class="topbar-left">
            <a  class="logo">
                <img src="../../../assets/images/logo.png">
            </a>
        </div>
        <!-- User box -->
        <div class="user-box">
            <div class="user-img">
                <img src="assets/images/profile.png" alt="user-img" title="Mat Helme" class="rounded-circle img-fluid">
            </div>
            <h5><a href="#">{{userName}}</a> </h5>
            <p class="text-muted">{{email}}</p>
        </div>

        <!--- Sidemenu -->
        <div id="sidebar-menu">
            <ul class="metismenu" id="side-menu">
                <li>
                    <a (click)="nave('/zones')"  routerLinkActive="active">
                        <i class="mdi mdi-map-marker-multiple"></i> <span> Zones </span>
                    </a>
                </li>
                <li>
                    <a (click)="nave('/orders')"  routerLinkActive="active">
                        <i class="fi-box"></i> <span> All Orders </span>
                    </a>
                </li>
                <li>
                    <a  (click)="nave('/categories')" routerLinkActive="active">
                        <i class="fi-layout"></i> <span> Categories </span>
                    </a>
                </li>
                <li>
                    <a (click)="nave('/products')" routerLinkActive="active">
                        <i class="fi-layout"></i> <span> Products </span>
                    </a>
                </li>
                <li>
                    <a (click)="nave('/addColor')" routerLinkActive="active">
                        <i class="fi-cog "></i> <span> Settinges </span>
                    </a>
                </li>
                
            </ul>
        </div>
        <!-- Sidebar -->
        <div class="clearfix"></div>
    </div>
    <!-- Sidebar -left -->
</div>
<!-- Left Sidebar End -->