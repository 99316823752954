import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ZonesResponse } from 'src/app/components/models/zones';

@Injectable({
  providedIn: 'root'
})
export class ApisZonesService {

  BaseUrl ='http://api.saydty.apishipping.iraq-soft.info/'
  token :string;
  lang :string;
  constructor(private http: HttpClient) { }

  public getZones():  Observable<ZonesResponse>{
    this.token = localStorage.getItem("token")
    this.lang = localStorage.getItem("lang") ??"ar";
    let headers: HttpHeaders = new HttpHeaders()
    .set("lang" , this.lang)
    .set("Authorization","Bearer " + this.token);
    return this.http.get<ZonesResponse>(this.BaseUrl+ 'admin/zone/get' ,{headers});
  }

  public deleteZones(id :number):  Observable<ZonesResponse>{
    this.token = localStorage.getItem("token")
    this.lang = localStorage.getItem("lang") ??"ar";
    let headers: HttpHeaders = new HttpHeaders()
    .set("lang" , this.lang)
    .set("Authorization","Bearer " + this.token);
    return this.http.post<ZonesResponse>(this.BaseUrl+ 'admin/zone/delete/'+ id ,null,{headers});
  }

  public editeZones(body ,id : number):  Observable<ZonesResponse>{
    this.token = localStorage.getItem("token")
    this.lang = localStorage.getItem("lang") ??"ar";
    let headers: HttpHeaders = new HttpHeaders()
    .set("lang" , this.lang)
    .set("Authorization","Bearer " + this.token);
    return this.http.post<ZonesResponse>(this.BaseUrl+ 'admin/zone/edit/'+ id ,body,{headers});
  }
  public addZone(body):  Observable<ZonesResponse>{
    this.token = localStorage.getItem("token")
    this.lang = localStorage.getItem("lang") ??"ar";
    let headers: HttpHeaders = new HttpHeaders()
    .set("lang" , this.lang)
    .set("Authorization","Bearer " + this.token);
    return this.http.post<ZonesResponse>(this.BaseUrl+ 'admin/zone/add' ,body,{headers});
  }
}
